import axios from "axios";
import { LOGIN_ } from "../../constant/API constant";

export const Login = (number, password) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${LOGIN_}`,
    {
      number,
      password,
    },
    {
      headers: {
        origin: "https://donation.rishikeshdham.org",
      },
    }
  );
};
