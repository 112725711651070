import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./donationItem";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import OrgContext from "../../context/OrgContext/OrgContext";
import {
  Download_Slip,
  UpdateTrasectionStatusSlip,
  generateOtp,
} from "../../api/DonationBillApi/DonationCillapi";
import { toast, Zoom } from "react-toastify";
import { ERROR_CODE, PAYMENT_SLIP_STATUS, TypeFilter } from "../../constant";
import LogOut from "../../utils/logout";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import OtpComp from "../../common/OtpComp";
import { useNavigate, useParams } from "react-router";

const Organization = () => {
  const {
    sortOrder,
    sortBy,
    setSortOrder,
    setSortBy,
    progress,
    setProgress,
    pageSize,
    setPageSize,
    page,
    setPage,
    record,
    getData,
    otpModel,
    setOtpModel,
    idForOtp,
    setIdForOtp,
    showOtpError,
    setShowOtpError,
    getSucOtp,
    setGetSucOtp,
    slipIdFilter,
    setOtp,
    filters,
    setFilters,
  } = useContext(OrgContext);
  const { loaderShow, setLoaderShow } = useContext(OrganizationContext);
  const history = useNavigate();
  const params = useParams();

  useEffect(() => {
    let Type = {
      type: TypeFilter.INCOME,
    };
    if (params.type === "expanse") {
      Type.type = TypeFilter.EXPANCE;
    }
    getData(Type);
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, slipIdFilter, params.type, filters]);

  useEffect(() => {
    setFilters({
      startDate: "",
      endDate: "",
      bankId: "",
      PaymentType: "",
    });
  }, []);

  const handleStatusChange = async (templeId, trustId, slipId) => {
    try {
      let formData = {
        status: PAYMENT_SLIP_STATUS.ACCEPTED,
        templeId,
        trustId,
      };
      if (window.confirm("Are you sure you want to accept? ")) {
        const { data } = await UpdateTrasectionStatusSlip(formData, slipId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          getData();
        } else {
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const otpApiHandler = async (slipId) => {
    try {
      let formData = {
        type: "delete",
        slipId,
      };
      const { data } = await generateOtp(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setGetSucOtp(true);
        setLoaderShow(false);
        setOtp(["", "", "", "", "", ""]);
        setOtpModel(!otpModel);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteDoantionSLip = async (slipId) => {
    setIdForOtp(slipId);
    setLoaderShow(!loaderShow);
    otpApiHandler(slipId);
  };

  const deleteDonation = async ({ enteredOtp }) => {
    setLoaderShow(!loaderShow);
    let fromData = {
      slipId: idForOtp,
      otp: enteredOtp,
    };
    try {
      const { data } = await Download_Slip(fromData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setOtpModel(false);
        setLoaderShow(false);
        setShowOtpError("");
        setGetSucOtp(false);
      }
    } catch (error) {
      if (error?.response?.data?.status === ERROR_CODE.UNAUTHORIZED) {
        LogOut(history);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setShowOtpError(error?.response?.data?.message);
      setLoaderShow(false);
    }
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationItem
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          handleStatusChange={handleStatusChange}
          sorting={sorting}
          deleteDoantionSLip={deleteDoantionSLip}
        />
        <OtpComp
          otpModel={otpModel}
          setOtpModel={setOtpModel}
          idForOtp={idForOtp}
          resendOtpApi={otpApiHandler}
          otpSubmitHandler={deleteDonation}
          showOtpError={showOtpError}
          getSucOtp={getSucOtp}
        />
      </div>
    </>
  );
};
export default Organization;
