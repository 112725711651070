import { AddCircle, DeleteForever } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import {
  DateFormateChange,
  isEmpty,
  maxLengthCheck,
  randomStr,
} from "../../utils/helper";
import "./PublicPage.css";
import { Donation_MasterList_Public } from "../../api/DonationMasterApi/DonationMasterApi";
import { ACCOUNT_TYPE, CLIENT_TYPE } from "../../constant";
import { toast, Zoom } from "react-toastify";
import PublicPageContext from "../../context/PublicPageContext/PublicPageContext";
import { FILE_API } from "../../api/Bank Master api/BankMasterAPi";
import {
  CreateTrasectionSlip_Public,
  Donation_bill_Details_Public,
} from "../../api/DonationBillApi/DonationCillapi";
import { useNavigate, useParams } from "react-router";
import LoaderResoinse from "../../loader/loader";
import DonarPublicHeaderComponent from "../../common/commonComp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DialCodeList } from "../../api/DialCodeApi/DialCodeApi";

const PublicPage = () => {
  const {
    paymenName,
    setPaymentName,
    address,
    setAddress,
    name,
    setName,
    date,
    setDate,
    contactNo,
    setContactNo,
    village,
    setVillage,
    pancard,
    setPanCard,
    aadhaarCard,
    setAadhaarCard,
    byHand,
    setByhand,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    personId,
    setPersonaId,
    setTrustId,
    note,
    setNote,
    transectionNo,
    setTransectionNo,
    setPublicPageThankYou,
    dialCode,
    setDialCode,
  } = useContext(PublicPageContext);
  const [feilds, setFeilds] = useState([
    {
      id: randomStr(5),
      accountId: Number(null),
      amount: Number(null),
      name: null,
    },
  ]);
  const history = useNavigate();
  const [accountDrop, setAccountDrop] = useState([]);
  const [accountDropCopy, setAccountDropCopy] = useState([]);
  const [click, setClick] = useState(false);
  const [dialCodeJson, setDialCodeJson] = useState([]);
  const { bankId, trustUuid, orgId, templeUuid } = useParams();

  const handleAdd = (e) => {
    e.preventDefault();
    setFeilds([
      ...feilds,
      {
        id: randomStr(5),
        accountId: Number(null),
        amount: Number(null),
        name: null,
      },
    ]);
  };

  const hanldeRemove = async (i, e) => {
    e.preventDefault();
    const values = [...feilds];
    values.splice(i, 1);
    setFeilds(values);
  };

  const handleInputChange = (i, e) => {
    const values = [...feilds];
    values[i][e.target.name] = e.target.value;
    setFeilds(values);
  };
  const handleChange1 = (param, index) => {
    feilds[index].accountId = param.value;
    feilds[index].amount = param.defaultAmount;
    setAccountDrop(
      accountDrop.filter((data) => data.accountId !== param.value)
    );
    setAccountDrop(
      accountDropCopy.filter(
        (data) => !feilds.map((v) => v.accountId).includes(data.accountId)
      )
    );
  };

  const accountChange = (opt, i) => {
    setPaymentName(opt);
    feilds[i].name = paymenName;
  };

  const accountDropDown = async () => {
    let paramsObj = {
      search: { type: ACCOUNT_TYPE.inward },
      trustUuid: trustUuid,
      templeUuid,
    };
    try {
      const { data } = await Donation_MasterList_Public(paramsObj);
      if (data.status === 200) {
        setAccountDrop(data.payload.data);
        setAccountDropCopy(data.payload.data);
      } else {
        setAccountDrop([]);
        setAccountDropCopy([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    accountDropDown();
    setDialCodeJson(DialCodeList());
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "black" : "black",
    }),
  };

  const showHandle = async (mobile) => {
    try {
      const { data } = await Donation_bill_Details_Public(mobile, orgId);
      if (data?.status === 200) {
        let payload = data.payload.data;
        if (payload !== null && payload !== undefined) {
          setPersonaId(payload.personId ? payload.personId : null);
          setName(payload.name ? payload.name : null);
          setVillage(payload.village ? payload.village : null);
          setPanCard(payload.panCardNo ? payload.panCardNo : null);
          setAadhaarCard(payload.aadhaarCard ? payload.aadhaarCard : null);
          setCity(payload.city ? payload.city : null);
          setState(payload.state ? payload.state : null);
          setCountry(payload.country ? payload.country : null);
          setAddress(payload.address ? payload.address : null);
          setDate(
            payload.dob ? new Date(DateFormateChange(payload.dob)) : null
          );
        } else {
          return true;
        }
      } else {
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const emptyUserDetails = () => {
    setName("");
    setVillage("");
    setPanCard("");
    setAadhaarCard("");
    setCity("");
    setState("");
    setCountry("");
    setAddress("");
  };

  const handleContactChange = async (e) => {
    setContactNo(e.target.value);
    if (e.target.value.length === 10) {
      showHandle(`${dialCode} ${e.target.value}`);
    } else {
      emptyUserDetails();
    }
  };

  const handleChangeDialCode = async (e) => {
    const { value } = e.target;
    if (value && contactNo?.length === 10) {
      const isEmpty = await showHandle(`${value} ${contactNo}`);
      if (isEmpty && typeof isEmpty === "boolean") {
        emptyUserDetails();
      }
    }
    setDialCode(value);
  };

  const emplyFeild = () => {
    setPersonaId("");
    setDialCode("+91");
    setContactNo("");
    setName("");
    setVillage("");
    setPanCard("");
    setAadhaarCard("");
    setAddress("");
    setFeilds([
      {
        id: randomStr(5),
        accountId: Number(""),
        amount: Number(""),
        name: "",
      },
    ]);
    setNote("");
    setTransectionNo("");
    setByhand("");
    setCity("");
    setState("");
    setCountry("");
    setTrustId("");
    setDate("");
  };

  const pageItemUploadFile = async ({ files, type }) => {
    let formData1 = new FormData();
    formData1.append("type", type);
    formData1.append("file", files[0]);

    try {
      const { data } = await FILE_API(formData1);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });

        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setClick(!click);
    let donationArray = feilds.map(({ accountId, amount }) => ({
      accountId,
      amount,
    }));
    try {
      let formData = isEmpty({
        personId: personId,
        mobile: `${dialCode} ${contactNo}`,
        name,
        village: village,
        panCardNo: pancard,
        aadhaarCard,
        address,
        donationArray,
        bankId,
        note,
        transactionNo: transectionNo,
        byHand,
        city,
        orgId,
        templeId: templeUuid,
        state,
        country,
        trustId: trustUuid,
        dob: moment(date).format("yyyy-MM-DD"),
        paymentType: "UPI",
        paymentMode: "Bank",
      });

      if (e.target.screenShort && e.target.screenShort.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.screenShort.files,
          type: CLIENT_TYPE.PUBLIC_FORM,
        });
        formData["attachment"] = imageUrl;
      }
      const { data } = await CreateTrasectionSlip_Public(formData);
      if (data.status === 200) {
        setClick(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        emplyFeild();
        history(`/Thankyou/${orgId}/${templeUuid}/${trustUuid}/${bankId}`);
        setPublicPageThankYou(true);
        setClick(false);
      } else {
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const TotalAmount = () => {
    let total = 0;
    for (let amount of feilds) {
      total += +amount.amount;
    }
    return total;
  };

  return (
    <div className="panel">
      {click === true && <LoaderResoinse />}
      <div className="backGround">
        <DonarPublicHeaderComponent secondTitle="#ffdda3" therdTitle="black" />
        <Col
          sm="12"
          className="container"
          style={{ paddingTop: "0rem", paddingBottom: "0rem" }}
        >
          <Card className="backgroundcolor">
            <CardBody>
              <Row>
                <Col sm="12">
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Contact No.*"}
                        </Label>
                        <div className="d-flex">
                          <select
                            className="form-control"
                            style={{ width: "67px" }}
                            onChange={(e) => {
                              handleChangeDialCode(e);
                            }}
                            value={dialCode}
                          >
                            <option value={""}>{"-- select --"}</option>{" "}
                            {dialCodeJson.map((elem) => {
                              return (
                                <option value={elem.code}>
                                  {elem.country}
                                </option>
                              );
                            })}
                          </select>
                          <Input
                            className="form-control colorBlack"
                            name="contactno"
                            type="number"
                            id="contactno"
                            placeholder={"Contact No."}
                            min={0}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            value={contactNo}
                            onChange={(e) => handleContactChange(e)}
                            onWheel={(e) => e.target.blur()}
                            required
                          />
                        </div>
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">{"Name*"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"Name"}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">{"Village"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"Village"}
                          value={village}
                          onChange={(e) => setVillage(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{"Pan Card"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"Pan Card"}
                          min={0}
                          maxLength={10}
                          onInput={maxLengthCheck}
                          value={pancard}
                          onChange={(e) => setPanCard(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Aadhaarcard"}
                        </Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="number"
                          id="contactno"
                          placeholder={"Aadhaarcard"}
                          min={0}
                          maxLength={12}
                          onInput={maxLengthCheck}
                          value={aadhaarCard}
                          onChange={(e) => setAadhaarCard(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{"By Hand"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"By Hand"}
                          value={byHand}
                          onChange={(e) => setByhand(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">
                          {"Birthdate"}
                        </Label>
                        <DatePicker
                          className="form-control"
                          selected={date}
                          onChange={(date) => setDate(date)}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                        />
                        {/* <Input
                          className="form-control"
                          name="totalamount"
                          type="date"
                          id="totalamount"
                          placeholder="dd-mm-yyyy"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        /> */}
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{"City"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"City"}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{"State"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"State"}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom01">{"Country"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="text"
                          id="contactno"
                          placeholder={"Country"}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      
                    </Row> */}
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom01">{"Address"}</Label>
                        <Input
                          className="form-control colorBlack"
                          name="contactno"
                          type="textarea"
                          id="contactno"
                          Col={5}
                          placeholder={"Address"}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <hr style={{ color: "cadetblue", border: "2px solid" }} />

                    <>
                      {feilds.map((elem, i) => (
                        <div key={elem.id}>
                          <Row>
                            <Col md="5 mb-4">
                              <Label htmlFor="validationCustom01">
                                {"Donation Type *"}
                              </Label>
                              <Select
                                aria-labelledby="aria-label"
                                inputId="aria-example-input"
                                name="donationtype"
                                onChange={(opt) => {
                                  handleChange1(opt, i);
                                }}
                                defaultInputValue={
                                  accountDropCopy.find(
                                    (v) => v.accountId === elem.accountId
                                  )?.name || ""
                                }
                                onInputChange={(opt) => accountChange(opt, i)}
                                options={accountDrop.map((elem, i) => {
                                  return {
                                    value: elem.accountId,
                                    label: elem.name,
                                    defaultAmount: elem.defaultAmount,
                                  };
                                })}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    text: "#3599B8",
                                    font: "#3599B8",
                                    primary25: "#3599B8",
                                    primary: "#3599B8",
                                    neutral80: "black",
                                    color: "black",
                                  },
                                })}
                                styles={customStyles}
                              />
                              <div className="invalid-feedback">
                                {"Please provide a valid ORG Name."}
                              </div>
                            </Col>
                            <Col md="5 mb-4">
                              <Label htmlFor="validationCustom01">
                                {"Amount *"}
                              </Label>
                              <Input
                                className="form-control colorBlack"
                                name="amount"
                                type="number"
                                id="amount"
                                value={elem.amount === 0 ? "" : elem.amount}
                                onChange={(e) => handleInputChange(i, e)}
                                placeholder={"Amount"}
                                onWheel={(e) => e.target.blur()}
                              />
                              <div className="invalid-feedback">
                                {"Please provide a valid ORG Name."}
                              </div>
                            </Col>
                            <Col
                              md="2 mb-4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: "12px",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                style={{ background: "#dcf1dc" }}
                                onClick={(e) => handleAdd(e)}
                              >
                                <AddCircle style={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                style={{
                                  background: "#e1d7d7",
                                  marginLeft: "45px",
                                }}
                                disabled={i === 0}
                                onClick={(e) => hanldeRemove(i, e)}
                              >
                                <DeleteForever style={{ color: "red" }} />
                              </IconButton>

                              <div className="invalid-feedback">
                                {"Please provide a valid ORG Name."}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Row>
                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">
                            {"Total Amount"}
                          </Label>
                          <Input
                            className="form-control colorBlack"
                            name="totalamount"
                            type="number"
                            id="totalamount"
                            placeholder={TotalAmount()}
                            disabled
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid ORG Name."}
                          </div>
                        </Col>

                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">
                            {"Transaction No."}
                          </Label>
                          <Input
                            className="form-control colorBlack"
                            name="transectionno"
                            type="text"
                            id="transectionno"
                            placeholder={"Transection No."}
                            value={transectionNo}
                            onChange={(e) => setTransectionNo(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid ORG Name."}
                          </div>
                        </Col>
                        <Col md="3 mb-4">
                          <Label htmlFor="validationCustom01">
                            {"Upload Screenshort *"}
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            name="screenShort"
                            id="transectionno"
                            placeholder={"Uploadre Screenshort."}
                            required
                            // value={transectionNo}
                            // onChange={(e) => setTransectionNo(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid ORG Name."}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12 mb-4">
                          <Label htmlFor="validationCustom01">{"Note"}</Label>
                          <textarea
                            className="form-control colorBlack"
                            name="note"
                            id="note"
                            placeholder={"Note"}
                            rows="3"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid ORG Name."}
                          </div>
                        </Col>
                      </Row>
                    </>

                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={click === true ? "desabled" : ""}
                      >
                        {"Submit"}
                      </button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default PublicPage;
