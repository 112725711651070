const DonarPublicHeaderComponent = (props) => {
  const { therdTitle } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "20px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            letterSpacing: "2px",
            color: "#e64141",
            fontWeight: "700",
          }}
        >
          ||श्री स्वामिनारायणो विजयतेतराम्||
        </p>
      </div>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '25px',
            letterSpacing: '2px',
            color: `${secondTitle}`
          }}
        >
          {'શ્રી સરધારધામ સંચાલિત'}
        </p>
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: "30px",
            letterSpacing: "2px",
            color: `${therdTitle}`,
          }}
        >
          {"શ્રી સ્વામિનારાયણ આશ્રમ"}
        </p>
      </div>
    </div>
  );
};

export default DonarPublicHeaderComponent;
