import * as React from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Donation_bill_List } from "../../api/DonationBillApi/DonationCillapi";
import OrgContext from "./OrgContext";
import html2pdf from "html2pdf-jspdf2";
import OrganizationContext from "../OrganizationContext/OrganizationContext";
import { randomStr } from "../../utils/helper";
import { ERROR_CODE, OTP_EXPIRY_TIME } from "../../constant";
import LogOut from "../../utils/logout";

const OrgState = (props) => {
  const { responseLoader, setResponseLoader } =
    React.useContext(OrganizationContext);

  const [formvalue, setformvalue] = useState([]); //API -->
  const [formvalueSlip, setformvalueSlip] = useState([]); //API -->
  const [trustDrop, setTrustDrop] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [donationTypeName, setDonationtypeName] = useState("");

  const [url, setUrl] = useState("");

  const [isfcCode, setIsfcCode] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [confirmAaccountNo, setConfirmAccountNo] = useState("");

  const [branch, setBranch] = useState("");
  const [bankId, setBankId] = useState("");

  const [columnFilter, setColumnFilter] = useState("");
  const [columnName, setColumnName] = useState("");

  const [name, setName] = useState("");
  const [dialCode, setDialCode] = useState("+91");
  const [trustId, setTrustId] = useState("");
  const [type, setType] = useState("");
  const [personId, setPersonaId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [address, setAddress] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [village, setVillage] = useState("");
  const [pancard, setPanCard] = useState("");
  const [aadhaarCard, setAadhaarCard] = useState("");
  const [byHand, setByhand] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [paymenName, setPaymentName] = useState("");
  const [note, setNote] = useState("");
  const [transectionNo, setTransectionNo] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [slipId, setSlipId] = useState("");
  const [slipData, setSlipData] = useState("");
  const [dob, setDob] = useState("");
  const [dodDate, setDodDate] = useState();
  const [dodMonth, setDodMonth] = useState();
  const [dodPaksha, setdodPaksha] = useState();
  const [isWpNumber, setIsWpNumber] = useState("");
  const [feilds, setFeilds] = useState([
    {
      id: randomStr(5),
      accountId: Number(""),
      amount: Number(""),
      name: "",
    },
  ]);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(OTP_EXPIRY_TIME);

  const [showHandle, setShowHanlde] = useState("");
  const [otpModel, setOtpModel] = useState(false);

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [model, setModel] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [isAksharvashi, setIsAksharvashi] = useState(false);
  const [dialCodeJson, setDialCodeJson] = useState([]);
  const [persons, setPersons] = useState([]);
  const [gujaratiDate, setGujaratiDate] = useState([]);
  const [gujaratiMonth, setGujaratiMonth] = useState([]);
  const [dialCodeKin, setDialCodeKin] = useState("+91");
  const [contactNoKin, setContactNoKin] = useState("");
  const [idForOtp, setIdForOtp] = useState(null);
  const [getOtp, setGetOtp] = useState(null);
  const [showOtpError, setShowOtpError] = useState("");
  const [getSucOtp, setGetSucOtp] = useState(false);
  const [slipIdFilter, setSlipIdFilter] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    bankId: "",
    PaymentType: "",
  });
  const getData = async (Type) => {
    if (sortOrder === "ASC" || sortOrder === "DSC") {
    } else {
      setResponseLoader(!responseLoader);
    }
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };

      debugger;

      Object.entries(filters).forEach((elem) => {
        if (elem[1] !== "") {
          paramsObj["search"] = { ...paramsObj.search, [elem[0]]: elem[1] };
        }
      });

      if (Type) {
        paramsObj["search"] = { ...paramsObj.search, ...Type };
      }

      if (slipIdFilter) {
        paramsObj.search = { ...paramsObj.search, slipNo: slipIdFilter };
      }
      const { data } = await Donation_bill_List(paramsObj);
      if (data.status === 200) {
        setformvalueSlip(data.payload.data);
        setRecord(data.pager.totalRecords);
        setResponseLoader(false);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const Print = () => {
    const invoice = document.getElementById("invoice");
    html2pdf().from(invoice).save();
    setModel(!model);
  };

  return (
    <>
      <OrgContext.Provider
        value={{
          formvalue,
          setformvalue,
          isfcCode,
          setIsfcCode,
          accountNo,
          setAccountNo,
          branch,
          setBranch,
          confirmAaccountNo,
          setConfirmAccountNo,
          showHandle,
          setShowHanlde,
          formvalueSlip,
          setformvalueSlip,
          slipId,
          setSlipId,
          slipData,
          setSlipData,
          isOpen,
          setIsOpen,
          dob,
          setDob,
          donationTypeName,
          setDonationtypeName,
          dialCode,
          setDialCode,

          columnFilter,
          setColumnFilter,
          columnName,
          setColumnName,

          address,
          setAddress,
          name,
          setName,
          contactNo,
          setContactNo,
          village,
          setVillage,
          pancard,
          setPanCard,
          aadhaarCard,
          setAadhaarCard,
          byHand,
          setByhand,
          city,
          setCity,
          state,
          setState,
          country,
          setCountry,
          personId,
          setPersonaId,
          paymentMode,
          setPaymentMode,
          paymenName,
          setPaymentName,
          note,
          setNote,
          transectionNo,
          setTransectionNo,
          paymentType,
          setPaymentType,
          trustId,
          setTrustId,
          type,
          setType,

          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          model,
          setModel,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          bankId,
          setBankId,

          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          getData,
          Print,
          trustDrop,
          setTrustDrop,
          feilds,
          setFeilds,
          isEdit,
          setIsEdit,
          isAksharvashi,
          setIsAksharvashi,
          dialCodeJson,
          setDialCodeJson,
          persons,
          setPersons,
          gujaratiDate,
          setGujaratiDate,
          gujaratiMonth,
          setGujaratiMonth,
          dodDate,
          setDodDate,
          dodMonth,
          setDodMonth,
          dodPaksha,
          setdodPaksha,
          dialCodeKin,
          setDialCodeKin,
          contactNoKin,
          setContactNoKin,
          otpModel,
          setOtpModel,
          idForOtp,
          setIdForOtp,
          getOtp,
          setGetOtp,
          showOtpError,
          setShowOtpError,
          getSucOtp,
          setGetSucOtp,
          otp,
          setOtp,
          timer,
          setTimer,
          slipIdFilter,
          setSlipIdFilter,
          isWpNumber,
          setIsWpNumber,
          filters,
          setFilters,
        }}
      >
        {props.children}
      </OrgContext.Provider>
    </>
  );
};

export default OrgState;
